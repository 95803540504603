import Vue from "vue";
import Vuex from "vuex";
import { Auth } from "aws-amplify";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setRole(state, payload) {
      state.roles = payload;
    },
  },
  actions: {
    async logout({ commit }) {
      commit("setUser", null);
      return await Auth.signOut();
    },
    async authAction({ commit }) {
      const userInfo = await Auth.currentUserInfo();
      if (userInfo) {
        commit("setUser", userInfo);

        const authenticatedUser = await Auth.currentAuthenticatedUser();
        const userRoles =
          authenticatedUser.signInUserSession.accessToken.payload[
            "cognito:groups"
          ];
        commit("setRole", userRoles);
      }
    },
  },
  modules: {},
});
