import { Auth, Storage } from "aws-amplify";
import _ from "lodash";
import { API } from "aws-amplify";

export class hpUser {
  constructor() {}

  getAvatarImage(key) {
    return new Promise((resolve, reject) => {
      Storage.get(key, {
        level: "public",
      }).then((result) => {
        resolve(result);
      });
    });
  }

  async getUsers() {
    const headers = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return new Promise(function (resolve, reject) {
      API.get("energyFitnessUserApi", "/users/listusers/", headers).then(
        (cognitoUsers) => {
          const users = _.orderBy(cognitoUsers.users, "firstName", "asc");
          resolve(users);
        }
      );
    });
  }

  async getUserByUsername(username) {
    const headers = {
      queryStringParameters: {
        email: username,
      }, // replace this with attributes you need
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return new Promise(function (resolve, reject) {
      API.get(
        "energyFitnessUserApi",
        "/users/getgroupbyusername/",
        headers
      ).then((cognitoUser) => {
        resolve(cognitoUser.users);
      });
    });
  }

  async getAwsCognitoGroups() {
    const headers = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return new Promise(function (resolve, reject) {
      API.get("energyFitnessUserApi", "/users/listgroups/", headers).then(
        (cognitoGroups) => {
          return resolve(cognitoGroups.groups);
        }
      );
    });
  }

  async createAwsCognitoUser(form) {
    const headers = {
      body: {
        form,
      },
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return new Promise(function (resolve, reject) {
      // const body = {
      //   form
      // }
      API.post("energyFitnessUserApi", "/users/createuser/", headers).then(
        (result) => {
          if (result.error) {
            reject(new Error(result.error));
          }
          return resolve(result.msg);
        }
      );
    });
  }

  async deleteAwsCognitoUser(username) {
    const headers = {
      queryStringParameters: {
        username: username,
      }, // replace this with attributes you need
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return new Promise(function (resolve, reject) {
      API.get(
        "energyFitnessUserApi",
        "/users/deletecognitouser/",
        headers
      ).then((result) => {
        return resolve(result.msg);
      });
    });
  }

  async updateAwsCognitoUserAttributes(addRoles, removeRoles, form) {
    const headers = {
      body: {
        addRoles,
        removeRoles,
        form,
      }, // replace this with attributes you need
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return new Promise(function (resolve, reject) {
      // const body = {
      //   addRoles, removeRoles, form,
      // }
      API.put(
        "energyFitnessUserApi",
        "/users/updatecognitouserattributes/",
        headers
      ).then((result) => {
        return resolve(result.msg);
      });
    });
  }

  async resetAwsCognitoPassword(username) {
    const headers = {
      queryStringParameters: {
        username: username,
      }, // replace this with attributes you need
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return new Promise(function (resolve, reject) {
      API.get(
        "energyFitnessUserApi",
        "/users/resetuserpassword/",
        headers
      ).then((result) => {
        return resolve(result.msg);
      });
    });
  }

  async disableUser(username) {
    const headers = {
      queryStringParameters: {
        username: username,
      }, // replace this with attributes you need
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return new Promise(function (resolve, reject) {
      API.get(
        "energyFitnessUserApi",
        "/users/disablecognitouser/",
        headers
      ).then((result) => {
        return resolve(result.msg);
      });
    });
  }

  async enableUser(username) {
    const headers = {
      queryStringParameters: {
        username: username,
      }, // replace this with attributes you need
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return new Promise(function (resolve, reject) {
      API.get(
        "energyFitnessUserApi",
        "/users/enablecognitouser/",
        headers
      ).then((result) => {
        return resolve(result.msg);
      });
    });
  }

  async updateAwsCognitoUserRoles(addRoles, removeRoles, username) {
    const headers = {
      body: {
        addRoles,
        removeRoles,
        username,
      }, // replace this with attributes you need
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    // const body = {
    //   addRoles, removeRoles, username,
    // }
    return new Promise(function (resolve, reject) {
      API.put(
        "energyFitnessUserApi",
        "/users/updatecognitouserroles/",
        headers
      ).then((result) => {
        return resolve(result.msg);
      });
    });
  }

  async getUserRoles() {
    return new Promise(function (resolve, reject) {
      Auth.currentAuthenticatedUser().then((user) => {
        const awsGroups =
          user.signInUserSession.accessToken.payload["cognito:groups"];
        if (awsGroups) {
          const roles =
            user.signInUserSession.accessToken.payload["cognito:groups"];
          resolve(roles);
        }
      });
    });
  }
}
