import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Profile from "../views/Profile.vue";
import Users from "../views/Users.vue";
import UserRoles from "../views/UserRoles.vue";
import ChangePassword from "../views/ChangePassword.vue";
import { Auth } from "aws-amplify";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { requiresAuth: true, layout: "DefaultLayout" },
    },
    {
      path: "/resetpassword",
      name: "resetpassword",
      component: ResetPassword,
      meta: { requiresAuth: false, layout: "DefaultLayout" },
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: { requiresAuth: true, layout: "DefaultLayout" },
    },
    {
      path: "/changepassword",
      name: "changepassword",
      component: ChangePassword,
      meta: { requiresAuth: true, layout: "DefaultLayout" },
    },
    {
      path: "/users",
      name: "users",
      component: Users,
      meta: {
        requiresAuth: true,
        role: ["SUPER-ADMIN"],
        layout: "DefaultLayout",
      },
    },
    {
      path: "/user-roles",
      name: "userroles",
      component: UserRoles,
      meta: {
        requiresAuth: true,
        role: ["SUPER-ADMIN"],
        layout: "DefaultLayout",
      },
    },
    {
      path: "/login",
      name: "login",
      meta: { layout: "FullPageLayout" },
      component: () => import("../views/Login.vue"),
    },
  ],
  mode: "history",
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = !!(await Auth.currentUserInfo());

  if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else {
    if (requiresAuth) {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      const userRoles =
        authenticatedUser.signInUserSession.accessToken.payload[
          "cognito:groups"
        ];
      let role = to.meta.role;
      if (typeof to.meta.role !== "undefined") {
        let authorised = false;
        for (var i = 0; i < userRoles.length; i++) {
          role.forEach(function (r) {
            if (userRoles[i] === r) {
              authorised = true;
            }
          });
        }
        if (!authorised) {
          next("/");
        }
      }
    }
    next();
  }
});

export default router;
